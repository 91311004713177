.sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  transition: $sidebar-transition;
  background: $sidebar-bg;
  border-right: $sidebar-border;
}

.sidebar-content {
  background: $sidebar-bg;
  transition: $sidebar-transition;

  .sidebar-sticky & {
    border-right: $sidebar-border;
  }
}

// Sidebar nested links
.sidebar-dropdown {
  .sidebar-dropdown {
    .sidebar-link {
      padding: $sidebar-link-child-second-padding;
    }

    .sidebar-dropdown {
      .sidebar-link {
        padding: $sidebar-link-child-third-padding;
      }
    }
  }
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-link:hover {
  background: grey;
}

.sidebar-item {
  width: max-content;
}

.sidebar-item.active > .sidebar-link,
.sidebar-item.active .sidebar-link:hover {
  color: $sidebar-link-active-color;
  background: $sidebar-link-active-bg;

  i,
  svg {
    color: $sidebar-link-icon-active-color;
    opacity: 0.5;
  }
}

// Children
.sidebar-dropdown .sidebar-link {
  color: $sidebar-link-child-color;
  font-weight: $sidebar-link-child-font-weight;
}

.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: $sidebar-link-child-hover-color;
  background: $sidebar-link-child-hover-bg;
  font-weight: $sidebar-link-hover-font-weight;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
  color: $sidebar-link-child-active-color;
  background: $sidebar-link-child-active-bg;
  font-weight: $sidebar-link-active-font-weight;
}

// Chevron
.sidebar [data-toggle='collapse'] {
  position: relative;
}

.sidebar [data-toggle='collapse']:before {
  content: ' ';
  border: solid;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  transition: all 0.2s ease-out;
}

.sidebar [aria-expanded='true']:before,
.sidebar [data-toggle='collapse']:not(.collapsed):before {
  transform: rotate(-135deg);
  top: 1.4rem;
}

// Sidebar brand
.sidebar-brand {
  font-weight: $sidebar-brand-font-weight;
  font-size: $sidebar-brand-font-size;
  padding: $sidebar-brand-padding-y $sidebar-brand-padding-x;
  color: $sidebar-brand-color;
  display: block;

  &:hover {
    text-decoration: none;
    color: $sidebar-brand-color;
  }

  &:focus {
    outline: 0;
  }

  svg,
  .feather {
    color: $sidebar-brand-icon-color;
    height: 24px;
    width: 24px;
    margin-left: -0.15rem;
    margin-right: 0.375rem;
  }
}

// Toggle states
.sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.sidebar {
  &.toggled {
    margin-left: -$sidebar-width;

    .sidebar-content {
      left: -$sidebar-width;
    }
  }

  // Workaround for IE bug, more info:
  // https://stackoverflow.com/a/25850649
  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    // Sidebar default state (on mobile)
    margin-left: -$sidebar-width;

    .sidebar-content {
      left: -$sidebar-width;
    }

    // Sidebar toggled state (on mobile)
    &.toggled {
      margin-left: 0;

      .sidebar-content {
        left: 0;
      }
    }

    // Sidebar collapsed state (on mobile)
    // layouts-sidebar-collapsed.html
    &-collapsed {
      margin-left: 0;

      .sidebar-content {
        left: 0;
      }

      &.toggled {
        margin-left: -$sidebar-width;

        .sidebar-content {
          left: -$sidebar-width;
        }
      }
    }
  }
}

.custom-sidebar {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: width 0.3s ease-in-out;
  position: sticky;
  top: 0;
}

@media (max-width: 768px) {
  .custom-sidebar.mobile-expanded {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw !important;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 9999;
    background-color: #111827;
  }
}

.toggle-button {
  position: absolute;
  top: 16px;
  right: -20px;
  z-index: 10;
}

.logo-container {
  padding: 8px;
  background-color: rgba(75, 85, 99, 0.1);
  border-radius: 50%;
}

.menu-item {
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.submenu {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.submenu-item {
  cursor: pointer;
}

.submenu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.with-extra {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-btn {
  border-radius: 50%;
  padding: 8px;
  background-color: white;
  border: 0;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.toggle-btn svg {
  color: #111928; /* Color negro para la flecha */
  transition: color 0.3s ease-in-out;
}

.toggle-btn:hover {
  background-color: #111928; /* Fondo negro */
}

.toggle-btn:hover svg {
  color: white; /* Flecha blanca */
}

// Sidebar links
.sidebar-link,
a.sidebar-link {
  display: block;
  padding: $sidebar-link-padding;
  font-weight: $sidebar-link-font-weight;
  transition: background 0.1s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    .arrow-icon {
      color: white !important; /* Flecha blanca en hover */
    }
  }
}

.sidebar-link .label {
  display: inline-block;
  width: 180px; /* Espacio reservado */
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.sidebar.collapsed .sidebar-link {
  width: 60px; /* Reducir tamaño cuando el sidebar está colapsado */
  justify-self: center;
}

.sidebar.collapsed .sidebar-link .label {
  opacity: 0; /* Oculta el texto suavemente */
}

/* Asegura que el Sidebar ocupe todo el alto */
.sidebar-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #111827; /* Mantiene el fondo del mismo color */
}

/* Hace que el Logo sea fijo arriba */
.sidebar-logo {
  height: 78px; /* Ajusta el tamaño del logo */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111827;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Hace que el menú sea Sticky y ocupe el espacio restante */
.sidebar-links {
  flex-grow: 1;
  overflow-y: auto; /* Permite scroll si hay muchos links */
  overflow-x: hidden;
  border-radius: 0px 0px 12px;
  padding-bottom: 20px;
  scrollbar-width: none; /* Firefox */
}

.sidebar-links::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.sidebar-link {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 12px 24px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  width: 250px; /* Espacio reservado para el texto */
  white-space: nowrap;
  overflow: hidden;

  .label {
    display: inline-block;
    width: 180px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    background-color: grey;
    .arrow-icon {
      color: white !important;
    }
  }
}

// Cuando el sidebar está colapsado
.sidebar.collapsed {
  .sidebar-link {
    width: 60px;
    justify-content: center;
  }

  .sidebar-link .label {
    opacity: 0;
  }
}

.sidebar-submenu-item {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
