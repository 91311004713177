.cardClient {
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  width: 280px;
  height: 100%;
  transition: all 0.3s ease;
  border: 2px solid transparent;

  &:hover {
    background: #f5fcff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

    h3.cardClient__title {
      color: #007bff;
    }

    .cardClient__footer-link {
      color: #007bff;
    }
  }

  &__badge-skill {
    background-color: #f0f0f0;
    color: #333;
    transition: background-color 0.3s ease-in-out;
    height: fit-content;

    &:hover {
      background-color: #0288d1;
      color: white;
    }
  }

  &__skills {
    height: 70px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.25rem;
    color: #333;
    margin: 0;
    font-weight: bold;
    min-height: 48px;
    transition: color 0.3s ease-in-out; /* transición suave */
  }

  &__description {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #555;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    padding-top: 0.75rem;
    margin-top: 1rem;

    &-info {
      display: flex;
      gap: 1rem;
      color: #666;

      .icon {
        margin-right: 0.5rem;
      }
    }

    &-link {
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #0056b3; /* color al hacer hover en el enlace */
        text-decoration: underline;
      }
    }
  }
}

/* Estilos para áreas específicas */
.Administrativo {
  background-color: #e3f2fd; /* Azul claro */
  border-color: #90caf9;
}

.Civil {
  background-color: #f3e5f5; /* Lila claro */
  border-color: #ce93d8;
}

.Laboral {
  background-color: #fff3e0; /* Naranja claro */
  border-color: #ffb74d;
}

.Mercantil {
  background-color: #e8f5e9; /* Verde claro */
  border-color: #81c784;
}

.Penal {
  background-color: #ffebee; /* Rojo claro */
  border-color: #e57373;
}

.Extraprocesal {
  background-color: #fbe9e7; /* Coral claro */
  border-color: #ff8a65;
}

/* Estilo para el efecto de sombra en IA */
.ia-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
