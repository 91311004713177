.footer {
  flex-shrink: 0;
  width: 100%;
  background: $white;
  border-top: 1px solid $gray-300;
  padding: $spacer $spacer * 0.75;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
