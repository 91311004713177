// Estilos de badges para estados de expedientes (estilo PigeonAI)
.pigeonAI-status-badge {
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0.15rem 0.35rem;
  border-radius: 0.6rem;
  font-weight: 500;
  font-size: 0.7rem;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
  border-width: 0.5px;

  // Estado: Esperando aceptación
  &.awaiting {
    background-color: rgba(108, 117, 125, 0.12);
    color: #495057;
    border: 1px solid rgba(108, 117, 125, 0.2);
    svg {
      color: #495057;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(108, 117, 125, 0.18);
    }
  }

  // Estado: Validando pago / Pospuesta
  &.pending {
    background-color: rgba(23, 57, 88, 0.12);
    color: #173958;
    border: 1px solid rgba(23, 57, 88, 0.2);
    svg {
      color: #173958;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(23, 57, 88, 0.18);
    }
  }

  // Estado: Pago rechazado / Cancelada / Cerrado
  &.error {
    background-color: rgba(220, 53, 69, 0.12);
    color: #dc3545;
    border: 1px solid rgba(220, 53, 69, 0.2);
    svg {
      color: #dc3545;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(220, 53, 69, 0.18);
    }
  }

  // Estado: Celebrada / Válido / Pagada / Creada
  &.success {
    background-color: rgba(40, 167, 69, 0.12);
    color: #28a745;
    border: 1px solid rgba(40, 167, 69, 0.2);
    svg {
      color: #28a745;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(40, 167, 69, 0.18);
    }
  }

  // Estado: Suspendida / Proforma
  &.info {
    background-color: rgba(66, 164, 255, 0.12);
    color: #42a4ff;
    border: 1px solid rgba(66, 164, 255, 0.2);
    svg {
      color: #42a4ff;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(66, 164, 255, 0.18);
    }
  }

  // Estado: Pendiente celebración
  &.primary {
    background-color: rgba(66, 99, 235, 0.12);
    color: #4263eb;
    border: 1px solid rgba(66, 99, 235, 0.2);
    svg {
      color: #4263eb;
      width: 10px;
      height: 10px;
      margin-right: 0.2rem;
    }
    &:hover {
      background-color: rgba(66, 99, 235, 0.18);
    }
  }

  // Adaptaciones responsivas
  @media (max-width: 768px) {
    padding: 0.1rem 0.3rem;
    font-size: 0.65rem;

    svg {
      width: 8px !important;
      height: 8px !important;
      margin-right: 0.15rem !important;
    }
  }
}

// Nueva paleta de estados para expedientes - usando colores específicos de la marca
.pigeonAI-status-colors {
  // Estado base (clase compartida para todos)
  &-badge {
    display: inline-flex;
    align-items: center;
    text-align: center;
    padding: 0.12rem 0.3rem;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.65rem;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
    border-width: 0.5px;

    svg {
      width: 9px;
      height: 9px;
      margin-right: 0.15rem;
    }

    @media (max-width: 768px) {
      padding: 0.1rem 0.25rem;
      font-size: 0.6rem;

      svg {
        width: 8px !important;
        height: 8px !important;
        margin-right: 0.1rem !important;
      }
    }
  }

  // Estado: Pendiente aceptación - Azul marino
  &-awaiting {
    background-color: rgba(23, 57, 88, 0.12);
    color: #173958;
    border: 0.5px solid rgba(23, 57, 88, 0.2);

    svg {
      color: #173958;
    }

    &:hover {
      background-color: rgba(23, 57, 88, 0.18);
    }
  }

  // Estado: Validando - Azul brillante
  &-validating {
    background-color: rgba(0, 152, 250, 0.12);
    color: #0098fa;
    border: 0.5px solid rgba(0, 152, 250, 0.2);

    svg {
      color: #0098fa;
    }

    &:hover {
      background-color: rgba(0, 152, 250, 0.18);
    }
  }

  // Estado: Rechazado/Cancelado - Rojo
  &-rejected {
    background-color: rgba(250, 35, 64, 0.12);
    color: #fa2340;
    border: 0.5px solid rgba(250, 35, 64, 0.2);

    svg {
      color: #fa2340;
    }

    &:hover {
      background-color: rgba(250, 35, 64, 0.18);
    }
  }

  // Estado: Pendiente celebración/creación - Azul brillante variante
  &-pending {
    background-color: rgba(0, 152, 250, 0.1);
    color: #0098fa;
    border: 0.5px solid rgba(0, 152, 250, 0.18);

    svg {
      color: #0098fa;
    }

    &:hover {
      background-color: rgba(0, 152, 250, 0.15);
    }
  }

  // Estado: Celebrada/Válido/Creado/Pagado - Verde brillante
  &-success {
    background-color: rgba(42, 189, 105, 0.12);
    color: #2abd69;
    border: 0.5px solid rgba(42, 189, 105, 0.2);

    svg {
      color: #2abd69;
    }

    &:hover {
      background-color: rgba(42, 189, 105, 0.18);
    }
  }

  // Estado: Suspendida/Proforma - Verde oscuro cambiado a Gris
  &-neutral {
    background-color: rgba(121, 85, 72, 0.12);
    color: #795548;
    border: 0.5px solid rgba(121, 85, 72, 0.2);

    svg {
      color: #795548;
    }

    &:hover {
      background-color: rgba(121, 85, 72, 0.18);
    }
  }

  // Estado: Pospuesto - Púrpura
  &-postponed {
    background-color: rgba(169, 72, 214, 0.12);
    color: #a948d6;
    border: 0.5px solid rgba(169, 72, 214, 0.2);

    svg {
      color: #a948d6;
    }

    &:hover {
      background-color: rgba(169, 72, 214, 0.18);
    }
  }

  // Estado: Cerrado/Finalizado - Azul marino más oscuro
  &-closed {
    background-color: rgba(23, 57, 88, 0.15);
    color: #173958;
    border: 0.5px solid rgba(23, 57, 88, 0.25);

    svg {
      color: #173958;
    }

    &:hover {
      background-color: rgba(23, 57, 88, 0.2);
    }
  }

  // Estado: Inválido - Rojo variante
  &-invalid {
    background-color: rgba(250, 35, 64, 0.15);
    color: #fa2340;
    border: 0.5px solid rgba(250, 35, 64, 0.25);

    svg {
      color: #fa2340;
    }

    &:hover {
      background-color: rgba(250, 35, 64, 0.2);
    }
  }

  // Estado: Creada/Created - Turquesa
  &-turquoise {
    background-color: rgba(0, 184, 212, 0.12);
    color: #00b8d4;
    border: 0.5px solid rgba(0, 184, 212, 0.2);

    svg {
      color: #00b8d4;
    }

    &:hover {
      background-color: rgba(0, 184, 212, 0.18);
    }
  }

  // Estado: Asignado/Assigned - Verde azulado
  &-assigned {
    background-color: rgba(32, 201, 151, 0.12);
    color: #20c997;
    border: 0.5px solid rgba(32, 201, 151, 0.2);

    svg {
      color: #20c997;
    }

    &:hover {
      background-color: rgba(32, 201, 151, 0.18);
    }
  }

  // Estado: En proceso/In Progress - Amarillo
  &-in-progress {
    background-color: rgba(255, 193, 7, 0.12);
    color: #ffc107;
    border: 0.5px solid rgba(255, 193, 7, 0.2);

    svg {
      color: #ffc107;
    }

    &:hover {
      background-color: rgba(255, 193, 7, 0.18);
    }
  }

  // Estado: Urgente - Naranja
  &-urgent {
    background-color: rgba(253, 126, 20, 0.12);
    color: #fd7e14;
    border: 0.5px solid rgba(253, 126, 20, 0.2);

    svg {
      color: #fd7e14;
    }

    &:hover {
      background-color: rgba(253, 126, 20, 0.18);
    }
  }
}
